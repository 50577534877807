import {
  Avatar,
  Badge,
  Image,
  Layout,
  Menu,
  Space,
  theme,
  Typography,
} from "antd";
import * as React from "react";
import {
  AppstoreOutlined,
  DesktopOutlined,
  ExperimentOutlined,
  HomeOutlined,
  IdcardOutlined,
  ImportOutlined,
  LinkOutlined,
  LogoutOutlined,
  ProductOutlined,
  ProfileOutlined,
  RetweetOutlined,
  SettingOutlined,
  SignatureOutlined,
  OrderedListOutlined,
  MailOutlined,
  TeamOutlined,
  ToolOutlined,
  UserOutlined,
  CodeOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import Sider from "antd/es/layout/Sider";
import { useAuth0 } from "@auth0/auth0-react";
import { auth0LogoutUrl } from "../config";
import logo_white from "../assets/logo_white.png";
import icon from "../assets/icon.png";
import getLogo from "./misc/logo";
import { parseJwt } from "../utils/jwt";
import { useScopes } from "../../providers/scopeProvider";

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

export const SideBar = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    loginWithPopup,
    logout,
    getAccessTokenSilently,
    isAuthenticated,
    buildLogoutUrl,
  } = useAuth0();

  const [selectedKeys, setSelectedKeys] = useState([location.pathname]);
  const [collapsed, setCollapsed] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const { hasScope } = useScopes();

  const {
    token: { colorBgContainer, borderRadiusLG, colorBorderSecondary },
  } = theme.useToken();

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      // decode and get obj
      const decodedToken = parseJwt(token);

      // get email from aetherx.ai/claims/email
      setUserEmail(decodedToken["aetherx.ai/claims/email"]);
    });
  }, []);

  useEffect(() => {
    setSelectedKeys([location.pathname]);
  }, [location]);

  function logoutHandler() {
    if (props.platform === "electron") {
      window.electronAPI.logout(auth0LogoutUrl);
      logout({ openUrl: false });
    } else {
      logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
    }
  }

  const onClick = (e) => {
    if (e.key === "sign-out") {
      logoutHandler();
    } else if (e.key === "log-in") {
      loginWithPopup()
        .then(() => {})
        .catch(() => {
          console.log("error");
        });
    } else {
      navigate(e.key);
    }
  };

  let items = [
    // getItem("Home", "/home", <HomeOutlined />),
    getItem("Task Launcher", "/task_launcher", <CodeOutlined />),
    getItem("Tasks", "/tasks", <ProfileOutlined />),
  ];

  if (hasScope("dev")) {
    items = items.concat([
      getItem("AI Systems", "/ai_systems", <ProductOutlined />),
      getItem("Agents", "/agents", <TeamOutlined />),
      getItem("Tools", "/tools", <ToolOutlined />),
      getItem("Connections", "/connections", <LinkOutlined />),
    ]);
  }

  items = items.concat([
    getItem(
      "HITL",
      "/hitl",
      <Badge dot={props.openHitlRequests}>
        <RetweetOutlined />
      </Badge>,
      [
        getItem(
          "Open Requests",
          "/hitl/requests",
          <Badge dot={props.openHitlRequests}>
            <ImportOutlined />
          </Badge>,
        ),
      ],
    ),
  ]);

  const bottomItems = [
    getItem("Changelog", "/changelog", <OrderedListOutlined />),
    getItem(
      "Help",
      "/help",
      <QuestionCircleOutlined />,
      [
        // getItem("Documentation", "/docs", <QuestionCircleOutlined />),
        getItem("Contact", "/contact", <MailOutlined />),
        // getItem("Send feedback", "/feedback", <MailOutlined />),
      ],
      "vertical",
    ),
    getItem(
      userEmail,
      "profile_menu",
      <UserOutlined />,
      [
        getItem("Usage", "/usage", <IdcardOutlined />),
        // getItem("Profile", "/profile", <IdcardOutlined />),
        getItem("Settings", "/settings", <ToolOutlined />),
        getItem("Sign Out", "sign-out", <LogoutOutlined />),
      ],
      "vertical",
    ),
  ];

  return (
    <Sider
      breakpoint="lg"
      collapsedWidth="50"
      width={220}
      style={{
        height: "100vh",
        background: colorBgContainer,
        borderRight: `1px solid ${colorBorderSecondary}`, // Using colorBorderSecondary for the border
      }}
      // collapsible
      collapsed={collapsed}
      onCollapse={(collapsed) => setCollapsed(collapsed)}
    >
      <img
        style={{
          height: collapsed ? 40 : 47,
          marginLeft: collapsed ? 4 : 10,
          marginTop: collapsed ? 4 : 10,
          marginBottom: 0,
        }}
        src={collapsed ? icon : getLogo()}
      />
      <Space direction="vertical" style={{ marginTop: 5 }}>
        {!collapsed && (
          <Typography.Text
            style={{
              marginLeft: 10,
              marginBottom: 0,
              fontWeight: 700,
              color: "#77F2A1",
            }}
            className={"raleway-400"}
          >
            PRIVATE ALPHA
          </Typography.Text>
        )}
      </Space>
      <Menu
        onClick={onClick}
        style={{
          width: "100%",
          overflow: "auto",
          border: "none",
          marginTop: 20,
        }}
        selectedKeys={selectedKeys}
        defaultOpenKeys={["/profile"]}
        mode="inline"
        items={items}
      />
      <Menu
        onClick={onClick}
        style={{
          position: "absolute",
          bottom: 0,
          width: "100%",
          border: "none",
        }}
        selectedKeys={selectedKeys}
        mode="vertical"
        items={bottomItems}
      />
    </Sider>
  );
};
