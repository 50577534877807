import React, { useEffect, useState } from "react";
import { AppContent } from "./appContent";
import { Auth0Provider } from "@auth0/auth0-react";
import { App, ConfigProvider, theme } from "antd";

import "./style.css";

export const ThemeContext = React.createContext(null);

const AppMain = (props) => {
  const [themeDark, setThemeDark] = useState(false);
  const [themeCompact, setThemeCompact] = useState(false);
  const [codeHighlight, setCodeHighlight] = useState("light");

  // Load settings from localStorage on component mount
  useEffect(() => {
    let storedThemeDark = localStorage.getItem("themeDark");
    const storedThemeCompact = localStorage.getItem("themeCompact");
    const storedCodeHighlight = localStorage.getItem("codeHighlight");

    // const isBrowserDefaultDark = () =>
    //   window.matchMedia("(prefers-color-scheme: dark)").matches;
    const isBrowserDefaultDark = () => true;  // ATM we always default to dark mode

    if (storedThemeDark === null && isBrowserDefaultDark() === true) {
      storedThemeDark = true;
    }

    if (storedThemeDark !== null) {
      setThemeDark(JSON.parse(storedThemeDark));
    }
    if (storedThemeCompact !== null) {
      setThemeCompact(JSON.parse(storedThemeCompact));
    }
    if (storedCodeHighlight !== null) {
      setCodeHighlight(storedCodeHighlight);
    }
  }, [setThemeDark, setThemeCompact, setCodeHighlight]);

  let algorithm = [];
  if (themeCompact) {
    algorithm.push(theme.compactAlgorithm);
  }
  if (themeDark) {
    algorithm.push(theme.darkAlgorithm);
  }

  return (
    <Auth0Provider
      domain={"dev-h5al1p433uvwkivu.us.auth0.com"}
      clientId={"2JcEjCgKxYKq3H0dIxsj8GohMfDD3gXK"}
      useRefreshTokens={true}
      cacheLocation="localstorage"
      authorizationParams={{
        redirect_uri: window.location.origin,
        // audience: "https://dev-h5al1p433uvwkivu.us.auth0.com/api/v
        audience: "https://api.alpha.prod.aetherx.ai/",
        scope:
          // "read:current_user update:current_user_metadata openid profile email full_access offline_access",
          "dev ax_staff",
      }}
    >
      <ThemeContext.Provider
        value={{
          themeDark,
          setThemeDark,
          themeCompact,
          setThemeCompact,
          codeHighlight,
          setCodeHighlight,
        }}
      >
        <ConfigProvider
          theme={{
            algorithm: algorithm,
            // algorithm: theme.darkAlgorithm,
            token: {
              colorPrimary: "#4F76F6",
              colorLink: "#4F76F6",
              colorLinkHover: "#4F76F6",
              borderRadius: 0,
              colorSuccess: themeDark ? "#77F2A1" : "#52c41a",

              colorBgBase: themeDark ? "#1F2B37" : "#FFFFFF",
            },
          }}
        >
          <App>
            <AppContent platform={props.platform} />
          </App>
        </ConfigProvider>
      </ThemeContext.Provider>
    </Auth0Provider>
  );
};

export default AppMain;
